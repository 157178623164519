<template>
  <section class="register flex justifyCenter alignCenter com-bg-img">
    <LoginCard
      title="用户注册"
      isLeftBtn
      :width="pxtorem('600px')"
      :height="pxtorem('720px')"
    >
      <div class="register-form-box">
        <el-form
          :model="registerForm"
          :rules="rules"
          ref="registerForm"
          label-width="100px"
          class="registerForm"
          label-position="top"
        >
          <el-form-item
            v-for="item in registerFormList"
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
          >
            <div class="email-code" v-if="item.isCode">
              <el-input
                v-model="registerForm[item.prop]"
                :placeholder="item.placeholder"
                maxlength="11"
              ></el-input>
              <!-- 验证码按钮 -->
              <div class="sendBtn" @click="sendEmailCode(item)">
                <Loading :loading="item.sendMessLoading">
                  <span>{{
                    item.isCount ? item.timeCount + "s" : "发送验证码"
                  }}</span>
                </Loading>
              </div>
            </div>
            <!-- 密码框 -->
            <el-input
              v-else-if="item.inputType === 'password'"
              :type="item.eyeFlag ? 'text' : 'password'"
              v-model.trim="registerForm[item.prop]"
              :placeholder="item.placeholder"
              autocomplete="new-password"
              maxlength="18"
            >
              <img
                slot="suffix"
                :src="item.eyeFlag ? eyeImg : eyeOffImg"
                class="eye-img"
                @click="item.eyeFlag = !item.eyeFlag"
              />
            </el-input>
            <!-- 输入框 -->
            <el-input
              v-else
              v-model="registerForm[item.prop]"
              :placeholder="item.placeholder"
              maxlength="6"
            ></el-input>
            <!-- 表单提示 -->
            <span v-if="item.tips" class="tips"
              ><i class="el-icon-warning-outline"></i>
              <span>{{ item.tips }}</span>
            </span>
          </el-form-item>
          <el-form-item prop="isAgree" class="agree-from-item">
            <el-checkbox v-model="isAgree"
              >创建帐户即表示您同意我们的<a
                target="_blank"
                href="/agreement?type=service"
                >《服务条款》</a
              >和<a target="_blank" href="/agreement?type=privacy"
                >《隐私政策》</a
              >
            </el-checkbox>
          </el-form-item>
        </el-form>
        <div
          class="operation-user verify-confirm"
          @click="RegisterConfirm('registerForm')"
        >
          <Loading :loading="registerLoading" color="#fff">
            <span>创建账户</span>
          </Loading>
        </div>
        <div class="has-register" @click="$router.push({ name: 'Login' })">
          <span>已有账户，去登录</span>
        </div>
      </div>
    </LoginCard>
  </section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { pxtorem } from "@/utils/common.js";
import LoginCard from "@/components/login/LoginCard.vue";
import Loading from "@/components/common/Loading.vue";
import { SHA256 } from "@/utils/sha256";

import {
  artRegister,
  sendMessRegister,
  isRegisterPhone,
} from "@/api//interface/login";
export default {
  components: {
    LoginCard,
    Loading,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        let reg =
          /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-z]|[A-Z]|[0-9]){6,18}$/;
        if (!reg.test(value)) {
          callback(new Error("请输入有效密码"));
        }
        callback();
      }
    };
    const validateCheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.registerForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      let reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (!value) {
        callback(new Error("请输入手机号码"));
      } else if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("请输入有效手机号码"));
      }
    };
    return {
      isAgree: false,
      registerLoading: false,
      eyeImg: require("@/assets/images/common/eye.png"),
      eyeOffImg: require("@/assets/images/common/eye-off.png"),
      // 表单
      registerForm: {
        phone: "",
        smsCode: "",
        password: "",
        confirmPassword: "",
      },
      registerFormList: [
        {
          label: "手机号",
          prop: "phone",
          placeholder: "请输入手机号",
          sendMessLoading: false,
          isCount: false,
          timeCount: 60,
          isCode: true,
        },
        {
          label: "短信验证码",
          prop: "smsCode",
          placeholder: "请输入短信验证码",
        },
        {
          label: "密码",
          inputType: "password",
          eyeFlag: false,
          prop: "password",
          tips: "请输入6到18位密码，包含数字、特殊字符、大写和小写字符(任意两种)",
          placeholder: "请输入密码",
        },
        {
          label: "确认密码",
          inputType: "password",
          eyeFlag: false,
          prop: "confirmPassword",
          placeholder: "请确认密码",
        },
      ],
      rules: {
        phone: {
          required: true,
          validator: validatePhone,
          trigger: ["change", "blur"],
        },
        smsCode: {
          required: true,
          message: "请输入短信验证码",
          trigger: ["change", "blur"],
        },
        password: {
          required: true,
          validator: validatePass,
          trigger: ["change", "blur"],
        },
        confirmPassword: {
          required: true,
          validator: validateCheckPass,
          trigger: ["change", "blur"],
        },
      },
    };
  },
  mounted() {
    // 监听回车按键
    document.onkeydown = (e) => {
      let key = e.key;
      if (key === "Enter") {
        this.RegisterConfirm("registerForm");
      }
    };
  },
  computed: {
    ...mapGetters(["deviceInfo"]),
  },
  methods: {
    ...mapActions(["setUserInfo"]),
    pxtorem,
    // 手机号是否已注册
    getIsRegisterPhone(formItem) {
      const { phone } = this.registerForm;
      formItem.sendMessLoading = true;
      // isRegisterPhone({ phone })
      //   .then((res) => {
      //     const { code } = res;
      //     if (code === 200) {
      this.sendMessRegister(formItem);
      //   } else {
      //     this.$message.info("手机号已注册！");
      //     formItem.sendMessLoading = false;
      //   }
      // })
      // .catch((_) => {});
    },
    // 短信接口
    sendMessRegister(formItem) {
      const { phone } = this.registerForm;
      formItem.sendMessLoading = true;
      sendMessRegister({ phone })
        .then((res) => {
          this.$message.success("发送成功！");
          this.noteInfoDisabled(formItem);
        })
        .catch()
        .finally(() => {
          formItem.sendMessLoading = false;
        });
    },
    // 注册
    artRegister() {
      this.registerLoading = true;
      artRegister({
        phone: this.registerForm.phone,
        smsCode: this.registerForm.smsCode,
        password: SHA256(this.registerForm.password),
        confirmPassword: SHA256(this.registerForm.confirmPassword),
      })
        .then((data) => {
          this.setUserInfo(data);
          this.$message.success("注册成功！");
          this.$router.push({
            name: "Index",
          });
        })
        .catch(() => {})
        .finally(() => {
          this.registerLoading = false;
        });
    },
    // 验证码重置
    noteInfoDisabled(item) {
      item.isCount = true;
      item.timeCount -= 1;
      let timer = setInterval(() => {
        if (item.timeCount <= 0) {
          item.isCount = false;
          item.timeCount = 60;
          clearInterval(timer);
          timer = null;
        } else {
          item.timeCount -= 1;
        }
      }, 1000);
    },

    // 点击发送邮件
    sendEmailCode(formItem) {
      if (formItem.isCount) return;
      this.$refs.registerForm.validateField(formItem.prop, (valid) => {
        if (!valid) {
          this.getIsRegisterPhone(formItem);
        }
      });
    },
    // 点击确认
    RegisterConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.isAgree) {
            this.$message.info("请先阅读并勾选同意《服务条款》和《隐私政策》");
            return;
          }
          this.artRegister();
        } else {
          return false;
        }
      });
    },
    // 重置表单
    resetForm() {
      this.isAgree = false;
      this.$refs["registerForm"].resetFields();
    },
  },
  beforeDestroy() {
    document.onkeydown = "";
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/login/_box_card.scss";
.register {
  height: 100vh;
  width: 100%;
  .box-card {
    padding: 62px 59px 80px;
  }
  .register-form-box {
    margin-top: 31px;
  }
  :deep() .el-form {
    .el-form-item {
      margin-bottom: 24px;
    }
    .agree-from-item {
      margin: 32px auto 0;
      padding-left: 31px;
      .el-form-item__content {
        line-height: 20px;
      }
      .el-checkbox__label {
        color: #fff;
        line-height: 20px;
        font-size: 14px;
        a {
          color: #fff;
        }
      }
    }
  }
  .operation-user {
    margin-top: 61px;
  }
  .has-register {
    margin-top: 14px;
  }
}
</style>
